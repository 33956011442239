// src
import '@/modules/header-actions';
import '@/utils/cookies';
import '@/global/js/core';
import '@/global/js/app';
import '@/lib/app';
import '@/lib/trackers/utm-tracker';
import '@/lib/trackers/mc-tracker';
import '@/lib/swiper/header';
import '@/lib/init-fwd-links';

import { createApp } from 'vue';

import initYoutubeConsent                                 from '@/lib/youtube/youtube-consent';
import { pushProductListViewEvent, pushProductViewEvent } from '@/utils/gtm';
import Logger, { LogLevel }                               from '@/utils/logger';
import { executeOnWindowLoad }                            from '@/utils/withWindowLoad';
import AccountDeletedModal                                from '@/vue/components/modals/AccountDeletedModal.vue';
import translator                                         from '@/vue/plugins/translator';
import { accountDeletedSessionKey }                       from '@/vue/stores/customer-store';

if (window.lvl3GtmProductInfo) {
    pushProductViewEvent(window.lvl3GtmProductInfo);
}

if (window.lvl2GtmProductsInfo) {
    pushProductListViewEvent(window.lvl2GtmProductsInfo);
}

executeOnWindowLoad(() => {
    const triggers = document.querySelectorAll(
        '[data-toggle="ekkoLightbox"][data-type="youtube"]',
    );
    initYoutubeConsent(Array.from(triggers) as HTMLElement[]);
    Logger.information(LogLevel.DEBUG);
});

// Show modal to user confirming their account was deleted
const accountDeleted               = window.sessionStorage.getItem(accountDeletedSessionKey);
const accountDeletedModalContainer = document.getElementById('account-deleted-modal-container');
if (accountDeleted !== null && accountDeletedModalContainer !== null) {
    const accountDeletedApp  = createApp(AccountDeletedModal);

    accountDeletedApp.use(translator);

    accountDeletedApp.mount(accountDeletedModalContainer);
    window.sessionStorage.removeItem(accountDeletedSessionKey);
}
